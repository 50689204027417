import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';
import { ComposableFunctionArgs } from '~/composables';

export const loadTotalQtyCommand = {
  execute: async (context: VsfContext, params?: ComposableFunctionArgs<{}>) => {
    Logger.debug('[Magento]: Load cart total qty');

    const apiState = context.$magento.config.state;
    if (apiState.getCartId()) {
      try {
        const result : any = await context.$magento.api.cartTotalQty(apiState.getCartId(), params?.customQuery ?? null);

        return result?.data?.cart?.total_quantity ?? 0;
      } catch (e) {
        Logger.error('[Magento]: cannot load cart', e);

        context.$magento.config.state.removeCartId(); // TODO: probably remove with upgrade; not ideal solution but ensures cart is reset

        return 0;
      }
    }

    return 0;
  },
};
