import { PiniaPluginContext } from 'pinia';
import { Plugin } from '@nuxt/types';
import { ref, set } from '@nuxtjs/composition-api';
// import StoreConfigGql from '~/plugins/query/StoreConfig.gql';
import type { StoreConfig } from '~/modules/GraphQL/types';

const storeConfigPlugin: Plugin = ({ $pinia, app }) => {
  // Disabled GraphQL request to enhance performance
  // const { data }: { data: { storeConfig?: StoreConfig } } = await app.$vsf.$magento.api.customQuery({ query: StoreConfigGql });
  const { data }: { data: { storeConfig?: StoreConfig } } = {
    data: {
      storeConfig: {
        store_code: 'default',
        default_title: '',
        store_name: 'Default Store View',
        default_display_currency_code: 'USD',
        locale: 'en_US',
        header_logo_src: 'stores/1/logo-with-otter-white.png',
        logo_width: 240,
        logo_height: 35,
        logo_alt: 'SwiftOtter: development agency logo',
      }
    },
  };

  $pinia.use(({ store }: PiniaPluginContext) => {
    if (store.$id !== 'magentoConfig') { return; }
    const storeConfig = ref(data?.storeConfig ?? {});

    // eslint-disable-next-line no-prototype-builtins
    if (!store.$state.hasOwnProperty('storeConfig')) {
      set(store.$state, 'storeConfig', storeConfig);
    } else {
      store.$state.storeConfig = storeConfig;
    }
  });
};

export default storeConfigPlugin;
