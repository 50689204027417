import config from '~/slicemachine.config.json';
import { Logger } from '~/helpers/logger';

export default function linkResolver(doc) {
  if (doc.url) {
    return doc.url;
  }

  try {
    if (doc.link_type === 'Document') {
      const route = config.routes?.find(route => route.type === doc.type);
      if (route) {
        return route.path?.replace(':uid', doc.uid || doc.slug);
      }
    }
  } catch (e) {
    Logger.warn('[Link] failed to create link', e);
  }

  return '/';
}
