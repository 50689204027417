import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import {
  Cart,
  CartItemInput,
} from '~/modules/GraphQL/types';
import { CustomQuery } from '~/types/core';

/** Params object used to add items to a cart */
export declare type AddProductsToCartInput = {
  cartId: string;
  cartItems: CartItemInput[];
};

export const addItemCommand = {
  execute: async (
    context: VsfContext,
    {
      product,
      quantity,
      currentCart,
      productConfiguration,
      customQuery,
    },
  ) => {
    Logger.debug('[Magento]: Add item to cart', {
      product,
      quantity,
      currentCart,
    });

    function handleErrors(response) {
      const firstError = response?.errors?.[0] || response?.user_errors?.[0];
      if (!firstError) {
        return;
      }

      if (firstError.message === 'The cart isn\'t active.') {
        context.$magento.config.state.removeCartId();
        firstError.message += ' Please try again.';
      }

      throw new Error(String(firstError.message));
    }

    const apiState = context.$magento.config.state;
    const cartId = apiState.getCartId();

    if (!product) {
      return;
    }
    // eslint-disable-next-line no-underscore-dangle
    switch (product.__typename) {
      case 'SimpleProduct':
      case 'TestProduct':
        const simpleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };

        const simpleProduct = await context.$magento.api.addProductsToCart(simpleCartInput, customQuery as CustomQuery);

        Logger.debug('[Result]:', { data: simpleProduct.data });

        if (simpleProduct.data.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(simpleProduct.data.addProductsToCart.user_errors[0].message));
        }

        // eslint-disable-next-line consistent-return
        return simpleProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'ConfigurableProduct':
        const selectedOptions = Object.values(productConfiguration as object);

        const configurableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              selected_options: selectedOptions,
            },
          ],
        };

        const configurableProduct = await context.$magento.api.addProductsToCart(configurableCartInput, customQuery as CustomQuery);
        Logger.debug('[Result]:', { data: configurableProduct.data });

        handleErrors(configurableProduct);

        // eslint-disable-next-line consistent-return
        return configurableProduct.data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'BundleProduct':
        const createEnteredOptions = () =>
        // eslint-disable-next-line implicit-arrow-linebreak
          product.bundle_options.map((bundleOption) => ({
            ...bundleOption,
            value: bundleOption.value.toString(),
          }));

        const bundleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              entered_options: createEnteredOptions(),
            },
          ],
        };

        const bundleProduct = await context.$magento.api.addProductsToCart(bundleCartInput, customQuery as CustomQuery);

        Logger.debug('[Result]:', { data: bundleProduct });

        handleErrors(bundleProduct);

        // eslint-disable-next-line consistent-return
        return bundleProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'DownloadableProduct':
        const downloadableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };

        const downloadableProduct = await context.$magento.api.addProductsToCart(downloadableCartInput, customQuery as CustomQuery);

        Logger.debug('[Result DownloadableProduct]:', { data: downloadableProduct });

        handleErrors(downloadableProduct);

        // eslint-disable-next-line consistent-return
        return downloadableProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'VirtualProduct':
        const virtualCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };
        const virtualProduct = await context.$magento.api.addProductsToCart(virtualCartInput, customQuery as CustomQuery);

        Logger.debug('[Result VirtualProduct]:', { data: virtualProduct });

        handleErrors(virtualProduct);

        // eslint-disable-next-line consistent-return
        return virtualProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      default:
        // eslint-disable-next-line no-underscore-dangle
        throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`);
    }
  },
};
