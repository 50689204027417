import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ceb15912 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _6ff9147a = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _66b2c7ce = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _78257916 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _61b344bd = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _8665c8ac = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _1aecf786 = () => interopDefault(import('../pages/create-password.vue' /* webpackChunkName: "pages/create-password" */))
const _22f1ee8a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _1ba897f8 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _57b00f90 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/DownloadableProducts.vue' /* webpackChunkName: "" */))
const _674e806c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _f8429318 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _416bb178 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _29811390 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _21d68638 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _47b763b3 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _530036c4 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _b3a33c7a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyPracticeTests.vue' /* webpackChunkName: "" */))
const _1d0bb296 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _526b59bb = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _60168702 = () => interopDefault(import('../pages/preview.vue' /* webpackChunkName: "pages/preview" */))
const _bc77a37a = () => interopDefault(import('../pages/slice-simulator.vue' /* webpackChunkName: "pages/slice-simulator" */))
const _2eec5c48 = () => interopDefault(import('../pages/test/Shared.vue' /* webpackChunkName: "pages/test/Shared" */))
const _b93dcdae = () => interopDefault(import('../pages/customer/account/create.vue' /* webpackChunkName: "pages/customer/account/create" */))
const _c128fdea = () => interopDefault(import('../pages/customer/account/forgotPassword.vue' /* webpackChunkName: "pages/customer/account/forgotPassword" */))
const _4141db4c = () => interopDefault(import('../pages/customer/account/login.vue' /* webpackChunkName: "pages/customer/account/login" */))
const _5889f216 = () => interopDefault(import('../pages/-Page.vue' /* webpackChunkName: "" */))
const _e6a80caa = () => interopDefault(import('../modules/catalog/pages/product.vue' /* webpackChunkName: "" */))
const _ec2bf31a = () => interopDefault(import('../pages/test/_id.vue' /* webpackChunkName: "pages/test/_id" */))
const _2f3b291c = () => interopDefault(import('../modules/catalog/pages/category.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _ceb15912,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _6ff9147a,
      name: "billing___default"
    }, {
      path: "payment",
      component: _66b2c7ce,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _78257916,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _61b344bd,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _8665c8ac,
      name: "user-account___default"
    }]
  }, {
    path: "/create-password",
    component: _1aecf786,
    name: "create-password___default"
  }, {
    path: "/customer",
    component: _22f1ee8a,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _1ba897f8,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "customer-downloadable-products",
      component: _57b00f90,
      meta: {"titleLabel":"Downloadable products"},
      name: "customer-downloadable-products___default"
    }, {
      path: "my-newsletter",
      component: _674e806c,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _f8429318,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _416bb178,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _29811390,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _21d68638,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "/reset-password",
      component: _47b763b3,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/create",
      component: _530036c4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "/customer/practice-tests",
      component: _b3a33c7a,
      meta: {"titleLabel":"My Practice Tests"},
      name: "my-practice-tests___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _1d0bb296,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _526b59bb,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/preview",
    component: _60168702,
    name: "preview___default"
  }, {
    path: "/slice-simulator",
    component: _bc77a37a,
    name: "slice-simulator___default"
  }, {
    path: "/test/Shared",
    component: _2eec5c48,
    name: "test-Shared___default"
  }, {
    path: "/customer/account/create",
    component: _b93dcdae,
    name: "customer-account-create___default"
  }, {
    path: "/customer/account/forgotPassword",
    component: _c128fdea,
    name: "customer-account-forgotPassword___default"
  }, {
    path: "/customer/account/login",
    component: _4141db4c,
    name: "customer-account-login___default"
  }, {
    path: "/",
    component: _5889f216,
    meta: {"overrideSlug":"home"},
    name: "home___default"
  }, {
    path: "/test/shared/index/token/:share_token+",
    component: _2eec5c48,
    name: "shared_test___default"
  }, {
    path: "/product/:slug",
    component: _e6a80caa,
    name: "magento_product___default"
  }, {
    path: "/test/:id?",
    component: _ec2bf31a,
    name: "test-id___default"
  }, {
    path: "/shop/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _2f3b291c,
    name: "magento_category___default"
  }, {
    path: "/:category/:subcategory?/:slug?",
    component: _5889f216,
    name: "cms_page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
