

































import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import { useUiState } from '~/composables';
import AppHeader from '~/components/AppHeader.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    AppHeader,
    LazyHydrate,
    IconSprite: () => import('~/components/General/IconSprite.vue'),
    AppFooter: () => import('~/components/AppFooter.vue'),
    CartSidebar: () => import('~/components/CartSidebar.vue'),
    WishlistSidebar: () => import('~/modules/wishlist/components/WishlistSidebar.vue'),
    LoginModal: () => import('~/modules/customer/components/LoginModal/LoginModal.vue'),
    Notification: () => import('~/components/Notification.vue')
  },

  setup () {
    const route = useRoute();
    const {
      isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal
    } = useUiState();

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route
    };
  },
  head: {
  }
});
