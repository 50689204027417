import { defineStore } from 'pinia';
import { Logger } from '~/helpers/logger';

export const useCmsStore = defineStore('cms', {
  state: () => ({
    menu: [],
    footer: []
  }),

  actions: {
    async loadSingle(type) {
      try {
        if (this[type].length === 0) {
          // @ts-ignore
          const data = (await this.$nuxt.$prismic.api.getSingle(type))?.data;

          if (data) {
            this[type] = data;
          } else {
            Logger.error('Failed to load Prismic Single');
            this[type] = [];
          }
        }
      } catch (e) {
        Logger.error('Failed to load Prismic Single');
      }
    }
  }
});
