<template>
  <div v-if="menu">
    <ul>
      <li v-for="link in menu" :key="link.id">
        <PrismicLink v-if="link.link" :field="link.link" class="menu-link">
          {{ $prismic.asText(link.link_label) }}
        </PrismicLink>
        <nuxt-link v-else-if="link.url" :to="`/${link.url}`" class="menu-link">
          {{ $prismic.asText(link.link_label) }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { useCmsStore } from '~/modules/cms/stores/cms';

export default {
  name: 'MainMenu',
  data: () => ({
    menu: [],
    logo: {}
  }),
  async fetch () {
    const cmsStore = useCmsStore();

    await cmsStore.loadSingle('menu');

    if (cmsStore.menu) {
      this.menu = cmsStore.menu.menu_items;
      this.logo = cmsStore.menu.logo || {};
    }
  }
};
</script>

<style scoped lang="scss">
nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul {
  display: flex;
  justify-content: space-around;

  @include for-mobile {
    padding-left: 0;
    font-size: clamp(0.65em, 3vw, 1em);
  }
}

li {
  display: inline-block;
}

.menu-link {
  padding: 0.5em;
  color: inherit;
  text-decoration: none;

  @include for-desktop {
    padding: 1em;
  }
}

a:hover {
  text-decoration: underline;
}
</style>
