import Vue from 'vue';

const stopLoader = () => {
  if (document) {
    document.body.classList.remove('inline-loader');
  }
};

export default ({ app }, inject) => {

  inject('startLoader', () => {
    if (document) {
      document.body.classList.add('inline-loader');
    }
  });

  inject('stopLoader', () => {
    stopLoader();
  });

  if (document) {
    document.body.addEventListener('fetchEnd', stopLoader);
  }
}
