import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';

/**
 * @param isDev
 * @param app
 */
export default ({ isDev, app }) => {
  Vue.use(Vue2TouchEvents);
};
